import axios from "axios";
import { logout } from "./auth";
import { url } from "./config";

const api = axios.create({
  baseURL: url,
});

export function getToken() {
  const token = localStorage.getItem("accessToken");
  return token;
}
function getRefreshToken() {
  const refreshToken = localStorage.getItem("refreshToken");
  return refreshToken;
}

async function refreshAccessToken() {
  const refreshToken = getRefreshToken();
  // console.log("refreshing access token");
  if (!refreshToken) {
    logout();
    //return Promise.reject("No refresh token available");
  }
  try {
    const res = await axios.post(`${url}/api/token/refresh/`, {
      refresh: refreshToken,
    });

    const newAccessToken = res.data.access;
    localStorage.setItem("accessToken", newAccessToken);
    api.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;

    return newAccessToken;
  } catch (error) {
    logout();
    //return Promise.reject("Failed to refresh access token");
  }
}

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshAccessToken();
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

/* api.interceptors.response.use(
  function (response) {
    if (response.headers["x-token-expired"] || response.status === 401) {
      if (getRefreshToken()) {
        return refreshAccessToken()
          .then((accessToken) => {
            response.config.headers.Authorization = `Bearer ${accessToken}`;

            return api(response.config);
          })
          .catch((refreshError) => {
            console.error(refreshError);
           
            return Promise.reject(refreshError);
          });
      } else {
        console.error("No refresh token available");
       
        return Promise.reject(response);
      }
    }
    return response;
  },
  function (error) {
    console.log(error);
    
  }
); */

api.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
export default api;
