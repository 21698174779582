import { Box, Button, Typography, Container, Grid } from "@mui/material";
import { keyframes, Stack } from "@mui/system";
import { pxToRem } from "px2rem2px";
import Guides from "../../components/Guides";
import Header from "../../components/Header";
import TreeImg from "../../images/footer-tree.svg";
import PhilosophyImg from "../../images/philosophy-img.png";
import Philosophy from "../../images/philosophy-img.png";
import CustomerService from "../../images/customer-service.svg";

const gradientAnimation = keyframes`
  0% { background-position: 0% 0%; }
  50% { background-position: 100% 100%; }
  100% { background-position: 0% 0%; }
`;

const aboutUsCardsDetails = [
  {icon: Philosophy, title: "Need 1 on 1 help?", description: "Schedule an appointment now to get personalized guidance and support. <span style='font-weight: 700;'> Reb Yakov Moshe </span> is here to answer your questions and provide the help.", btnName: "Schedule Appointment", rounded: true },
  {icon: CustomerService, title: "Technical Support", description: "For  <span style='font-weight: 700;'> website or account support, </span> contact our technical support team for quick and efficient assistance with any issues you encounter.", btnName: "Contact Support" }
]

const AboutUs = () => {
  return <Box position="relative" overflow="hidden">
  <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
  <Box
      position="relative"
      pt={pxToRem(30)}
      pb={pxToRem(40)}
      minHeight={pxToRem(540)}
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "linear-gradient(45deg, #026670, #fce181, #026670, #fce181)",
        backgroundSize: "600% 600%",
        animation: `${gradientAnimation} 30s ease infinite`,
        borderRadius: { xs: 0, md: pxToRem(40) },
        "&::before": {
          content: "''",
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translateX(-125%)",
          background: `url(${TreeImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          width: pxToRem(520),
          height: pxToRem(702),
          opacity: 0.4,
          "@media(max-width: 1300px)": {
            left: "30px",
            transform: "none",
          },
          "@media(max-width: 899px)": {
            left: "50%",
            transform: "translateX(-50%)",
          },
        },
      }}
    >
      <Header menuVisible />

      <Box className="header-padding" sx={{flexGrow: 1, alignItems: "center", display: "flex", justifyContent: "center"}}>
        <Typography component="h1" fontWeight="700" fontSize={{sm:pxToRem(60), md: pxToRem(70)}}>About Reb Yakov Moshe</Typography>
      </Box>
      <Guides color="rgba(0,0,0,0.03)" zIndex="0" />
    </Box>
    <Container sx={{ px: pxToRem(30), py: {sm: pxToRem(77), md: pxToRem(120)}}} disableGutters>
      <Box container spacing={11} sx={{alignItems: "center"}}>
        <Box>
          <Box
            position="relative"
            maxWidth={{ xs: "300px", sm: "310px", md: pxToRem(500) }}
            width="100%"
            height={{ xs: "310px", md: "auto" }}
            display="flex"
            borderRadius={pxToRem(20)}
            flexShrink={0}
            sx={{
              marginLeft: pxToRem(89),
              marginBottom: pxToRem(50),
              float: "right",
              "&::before": {
                content: "''",
                position: "absolute",
                right: { xs: "-25px", sm: "-30px", md: pxToRem(-40) },
                bottom: { xs: "-30px", md: pxToRem(-40) },
                width: { xs: "300px", sm: "310px", md: "88%" },
                height: { xs: "310px", md: "74.1%" },
                bgcolor: "#FCE181",
                borderRadius: pxToRem(20),
                zIndex: -1,
              },
            }}
            >
              <img
                src={PhilosophyImg}
                alt=""
                style={{
                  width: "100%",
                  maxWidth: pxToRem(500),
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: pxToRem(20),
                  transform: "scale(-1, 1)",
                }}
              />
          </Box>
          <Typography sx={{marginBottom: pxToRem(33), fontWeight: 700, pt: pxToRem(35), fontSize: {sm: pxToRem(42), md: pxToRem(45)}}} className="rtl-section" variant="sectionTitle" component="h2">וואס מיר טוען</Typography>
          <Typography sx={{fontWeight: 700, fontSize: {sm: pxToRem(25), md: pxToRem(32)}, lineHeight: pxToRem(48)}} className="rtl-section">
          נוהגים להרבות בתעניתים בשני וחמישי
          משום דאמרין במדרש זמרע' ה' עלה בחמישי
          לקבל לוחות אחרונות וירד בשני. ואומרים
          והוא רחום. ויש במדרש זג' זקנים תקנוהו.
          שתפסם מלך אחד ונתנם בג' ספינות בלא
          מנהיג. ושלחם לים ונתפזרו למרחקים.
          ולמקום שבאו הציר להם מאד ועמדו בתפלה
          וכל אחד יסד מקצת. ובכל חלק ח״ח שמות
          </Typography>
        </Box>
      </Box>
      <Box sx={{display: "flex", width: "100%"}}>
        <Typography sx={{pt: pxToRem(70), mb:pxToRem(60), fontWeight: 600, fontSize: {sm: pxToRem(22), md: pxToRem(32)}, lineHeight: pxToRem(48)}} className="rtl-section">
        נוהגים להרבות בתעניתים בשני וחמישי משום דאמרין במדרש זמרע' ה' עלה בחמישי לקבל
        לוחות אחרונות וירד בשני. ואומרים והוא רחום. ויש במדרש זג' זקנים תקנוהו. שתפסם מלך
        אחד ונתנם בג' ספינות בלא מנהיג. ושלחם לים ונתפזרו למרחקים. ולמקום שבאו הציר להם
        מאד ועמדו בתפלה וכל אחד יסד מקצת. ובכל חלק ח״ח שמות כנגד תפלת י״ח. ויחת אותו
        מלך שהציר להם בתחלואים רעים. ושלחו תפלתם בכל תפוצות ישראל לאמרה בשני וחמישי
        נוהגים להרבות בתעניתים בשני וחמישי משום דאמרין במדרש זמרע' ה' עלה בחמישי לקבל
        לוחות אחרונות וירד בשני. ואומרים והוא רחום. ויש במדרש זג' זקנים תקנוהו. שתפסם מלך
        אחד ונתנם בג' ספינות בלא מנהיג. ושלחם לים ונתפזרו למרחקים. ולמקום שבאו הציר להם
        מאד ועמדו בתפלה וכל אחד יסד מקצת. ובכל חלק ח״ח שמות כנגד תפלת י״ח. ויחת אותו
        מלך שהציר להם בתחלואים רעים. ושלחו תפלתם בכל תפוצות ישראל לאמרה בשני וחמישי 
        </Typography>
      </Box>
      <Grid container spacing={5.5}>
        {aboutUsCardsDetails.map((cardDetails, index) => (
          <Grid key={index} item sm={6}>
            <Stack sx={{backgroundColor:"#FFFFFF", borderRadius: pxToRem(20), flexDirection: {md: "row"}, gap: pxToRem(26),  padding: {xs: `${pxToRem(40)} ${pxToRem(30)}`, ssm: `${pxToRem(50)} ${pxToRem(45)}`, md: pxToRem(60)}, height: "100%", justifyContent: "space-between"}}>
              <Box sx={{width: pxToRem(72), height: pxToRem(72), borderRadius: cardDetails.rounded ? pxToRem(100) : "none", overflow: "hidden", flexShrink: "0"}}>
                <img src={cardDetails.icon} alt={cardDetails.title} />
              </Box>
              <Stack sx={{justifyContent: "space-between"}}>
                <Box>
                  <Typography variant="blockTitle" component="h3" fontSize={{sm: pxToRem(22), md: pxToRem(25)}} pb={pxToRem(15)}>{cardDetails.title}</Typography>
                  <Typography variant="medium" component="p" fontSize={pxToRem(18)} dangerouslySetInnerHTML={{ __html: cardDetails.description }} />
                </Box>
                <Box><Button variant="outlined" size="small" sx={{mt: {xs: pxToRem(20), ssm: pxToRem(30)}}}>{cardDetails.btnName}</Button></Box>
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Container>
</Box>
}
export default AboutUs;
