import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import { SwiperSlide } from "swiper/react";
import Slider from "../../components/Slider";
import TextLink from "../../components/TextLink";
import Slide from "../../components/Slide";
import { listCourses } from "../../api/course";
import { useEffect, useState } from "react";


const Courses = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [courses, setCourses] = useState([]);

  

  useEffect(() => {
    listCourses(4, 1, "", "", "", true).then((res) => {
      if (res?.data?.results) {
        const activeCourses = res.data.results.filter(course => course.status === "active");
        console.log(res);
        setCourses(activeCourses);
      }
    });
  }, []);

  return (
    <Container
      disableGutters
      sx={{
        mb: { xs: "75px", sm: "120px", md: pxToRem(150) },
        mt: { xs: "80px", sm: 0 },
      }}
    >
      <Stack alignItems="flex-start" gap="15px" mb="16px">
        <Typography
          variant="sectionTitle"
          component="h2"
          mb={{ xs: 0, sm: pxToRem(40) }}
        >
          Free <span className="highlighted">courses</span>
        </Typography>
        {mobile ? <SectionLink /> : null}
      </Stack>
      {courses.length > 0 ? (
        <>
          <Box
            sx={{
              display: { xs: "none", sm: "grid" },
              gridTemplateColumns: "1fr 1fr",
              rowGap: pxToRem(40),
              columnGap: pxToRem(20),
              mb: pxToRem(50),
            }}
          >
            {courses.map((course) => (
              <Slide
                key={course.id}
                image={course.thumb_nail}
                title={course.name}
                text={course.subtitle}
                videoLink={course.is_subscribed? `/course/${course.slug}` : `/course/${course.slug}/presubscribe`}
              />
            ))}
          </Box>
          <Box display={{ xs: "block", sm: "none" }} mx="-25px">
            <Slider
              slidesPerView={"auto"}
              spaceBetween={16}
              arrows
              init={false}
              style={{ padding: "20px 0 30px" }}
            >
              {courses.map((course) => (
                <SwiperSlide key={course.id}>
                  <Slide
                    key={course.id}
                    image={course.thumb_nail}
                    title={course.name}
                    text={course.subtitle}
                    videoLink={`/course/${course.slug}/`}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </Box>
        </>
      ) : (
        <Typography variant="body1" style={{ padding: "0 0 30px 0" }}>
          No free courses available at the moment...
        </Typography>
      )}
      {mobile ? null : <SectionLink />}
    </Container>
  );
};
const SectionLink = (props) => {
  return <TextLink href={"/courses"} {...props}>View All Courses</TextLink>;
};

export default Courses;
