import { Box, Button, Container, Stack, Typography } from "@mui/material";
import Guides from "../../components/Guides";
import Hero from "../../components/courses/Hero";
import Sidebar from "../../components/courses/Sidebar";
import CoursesSlider from "../../components/courses/CoursesSlider";
import ContentSlider from "../../components/courses/ContentSlider";
import NavGroup from "../../components/courses/NavGroup";
import MenuDropdownLink from "../../components/menuDropdown/MenuDropdownLink";
import ParshaSection from "./ParshaSection";

const Parsha = () => {
  return (
    <Box position="relative">
      <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
      <Hero title="Parsha" />

      <Box position="relative">
        <Container disableGutters>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            gap="6.5%"
            mb="100px"
          >
            <Sidebar>
              <NavGroup title="Recently Added">
                <MenuDropdownLink to="/">Submenu link 01</MenuDropdownLink>
                <MenuDropdownLink to="/">Another link 02</MenuDropdownLink>
                <MenuDropdownLink to="/">Submenu link 03</MenuDropdownLink>
                <MenuDropdownLink to="/">Another link 04</MenuDropdownLink>
                <MenuDropdownLink to="/">Another link 05</MenuDropdownLink>
                <MenuDropdownLink to="/">Another link 06</MenuDropdownLink>
              </NavGroup>

              <Box width="180px">
                <Typography
                  fontSize="14px"
                  lineHeight="18px"
                  fontWeight={600}
                  mb="20px"
                >
                  Receive notifications for new lessons!
                </Typography>
                <Button
                  fullWidth
                  variant="outlined"
                  size="small"
                  sx={{ fontSize: "14px !important" }}
                >
                  Get Notified
                </Button>
              </Box>
            </Sidebar>

            <Box flex={1} width="calc(100% - 300px)">
              <CoursesSlider />

              <ParshaSection />
            </Box>
          </Stack>
        </Container>
      </Box>

      <Container disableGutters>
        <ContentSlider />
      </Container>
    </Box>
  );
};

export default Parsha;
