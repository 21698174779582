import { useEffect, useState } from "react";
import api from "../../api/api";
import {
  Box,
  Typography,
  LinearProgress,
  linearProgressClasses,
  Link,
  useMediaQuery,
  useTheme,
  Skeleton,
  Button,
} from "@mui/material";
import { SwiperSlide } from "swiper/react";
import Slider from "../Slider";
import Course from "./Course";
import { styled } from "@mui/material/styles";
import { NavLink, useNavigate } from "react-router-dom";
import { pxToRem } from "px2rem2px";
import TextLink from "../../components/TextLink";
import PlayBtn from "../../components/PlayBtn";

import SlideWide from "../../images/video-slide-wide.jpg";
import SlideVideo1 from "../../images/video-slide2.jpg";
import SlideVideo2 from "../../images/video-slide3.jpg";
import { ReactComponent as Play } from "../../images/play.svg";
import { borderRadius, Stack } from "@mui/system";
import { ReactComponent as Chevron } from "../../images/chevron.svg";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: "100%",
  position: "absolute",
  bottom: 0,
  height: 5,
  borderRadius: 0,
  zIndex: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#717171",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: "#FCE181",
  },
}));

const HoverBox = styled(Box)(({ theme }) => ({
  transition: "0.2s ease",
  padding: "10px 10px 20px 10px",
  borderRadius: "20px",
  height: "85%",
  backgroundColor: "rgb(237, 236, 232,0.3)",
  "&:hover": {
    backgroundColor: "rgb(237, 236, 232)",
  },
  cursor:"pointer",
}));

const CoursesSlider = () => {
  const [videos, setVideos] = useState([]);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile__ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("djndjndjnd", videos);
  }, [videos]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    const fetchLatestWatchedCourses = async () => {
      try {
        const response = await api.get("/api/courses/continue_watching/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        console.log("fetchLatestWatchedCourses", response.data.results);
        setVideos(response.data.results);
      } catch (error) {
        console.error("Error fetching latest watched courses:", error);
      }
    };

    fetchLatestWatchedCourses();
  }, []);


  // redirect to course
  const handleRedirect = (slug) => {
    navigate(`/course/${slug}`);
  };

  return (
    <>
      {videos?.length > 0 && (
        <>
          <Stack
            flexDirection={{ ssm: "row" }}
            margin={{ xs: "0 -25px", ssm: "0 0 25px 0" }}
            padding={{ xs: `${pxToRem(49)} 25px 0 25px`, ssm: "0px" }}
            alignItems={{ ssm: "center" }}
            backgroundColor={{ xs: "#fff", ssm: "transparent" }}
            justifyContent={{ ssm: "space-between" }}
            gap={pxToRem(14)}
          >
            <Typography
              variant="sectionTitle"
              component="h2"
              fontSize={pxToRem(35)}
            >
              <span className="highlighted">Continue</span> Watching
            </Typography>
            {tablet && (
              <Button
                onClick={()=>navigate('/user/courses')}
                endIcon={<Chevron />}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  color: "#026670",
                  fontSize: "16px",
                  "& .MuiButton-endIcon": { ml: pxToRem(12) },
                }}
              >
                My Courses
              </Button>
            )}
          </Stack>
          <Box
            margin={{ xs: "0 -25px", ssm: "0px" }}
            sx={{
              backgroundColor: { xs: "#fff", ssm: "unset" },
              // borderRadius: {md: "20px"},
              p: { xs: `0 0 ${pxToRem(49)} 0`, ssm: "0" },

              "& .MuiContainer-root": {
                p: 0,
              },
              "& .swiper": {
                p: { xs: "0 0 0 0", sm: "10px" },
                m: { xs: 0, sm: "-10px -10px 0" },
                // "@media(max-width: 1330px)": {
                //   pb: "10px !important",
                // },
              },
              "& .swiper-slide": { width: "auto" },
              "& .arrow-prev": { left: "-46px", zIndex: 1 },
              "& .arrow-next": { right: "-46px", zIndex: 1 },
            }}
          >
            <Slider
              slidesPerView={1}
              spaceBetween={10}
              arrows
              breakpoints={{
                300: {
                  slidesPerView: 2,
                },
                406: {
                  slidesPerView: 2,
                },
                537: {
                  slidesPerView: 3,
                },
                654: {
                  slidesPerView: 4,
                },
                767: {
                  slidesPerView: 4,
                },
                900: {
                  slidesPerView: 3,
                },
                1017: {
                  slidesPerView: 3,
                },
                1250: {
                  slidesPerView: 4,
                },
              }}
              style={{
                backgroundColor: "#fff",
                borderRadius: mobile__ssm ? "" : "20px",
                padding: mobile__ssm ? "30px 25px 10px 25px" : "30px 25px",
              }}
            >
              {Array.isArray(videos) &&
                videos.map((v) => (
                  <>
                    <SwiperSlide
                      key={v.id}
                      style={{
                        display: "flex",
                        alignItems: "stretch",
                      }}
                    >
                      {/* Make the SwiperSlide a flex container */}
                      <HoverBox
                      
                      onClick={()=> handleRedirect(v.slug)}
                      >
                        {loading ? (
                          <Skeleton
                            variant="rounded"
                            animation="wave"
                            sx={{ borderRadius: "20px", flexGrow: 1 }}
                          >
                            <VideoSlide
                              key={v.id}
                              image={v.thumb_nail}
                              videoLink={`/course/${v.slug}`}
                              progress={v.progress}
                              title={v.name}
                              isMobile={mobile}
                            />
                          </Skeleton>
                        ) : (
                          <>
                            <VideoSlide
                              key={v.id}
                              image={v.thumb_nail}
                              videoLink={`/course/${v.slug}`}
                              progress={v.progress}
                              isMobile={mobile}
                            />
                          </>
                        )}

                        <Typography
                          className="slideTitle"
                          fontSize={pxToRem(16)}
                          lineHeight={pxToRem(20)}
                          fontWeight={600}
                          mt={pxToRem(14)}
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            textAlign: "center",
                          }}
                        >
                          {v.name}
                        </Typography>
                      </HoverBox>
                    </SwiperSlide>
                  </>
                ))}
            </Slider>
          </Box>
        </>
      )}
    </>
  );
};

const VideoSlide = (props) => {
  return (
    <>
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={{ xs: "100%", sm: "auto" }}
        height={{ xs: pxToRem(150), lg: pxToRem(190) }} // Adjust the height values here
        borderRadius="19px"
        overflow="hidden"
      >
        <Link
          to={props.videoLink}
          component={NavLink}
          sx={{ position: "absolute", zIndex: 1 }}
        >
          <PlayBtn
            width={{ xs: "40px", sm: pxToRem(66) }}
            height={{ xs: "40px", sm: pxToRem(66) }}
          >
            <Play
              color="#026670"
              width={pxToRem(20)}
              height={pxToRem(24)}
              style={{ marginLeft: "6%" }}
            />
          </PlayBtn>
        </Link>
        <Box position="absolute" width="0.1px">
          <PlayBtn />
        </Box>
        <BorderLinearProgress
          className="video-progress-bar"
          variant="determinate"
          value={props.progress}
        />
        <img
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            borderRadius: pxToRem(20),
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          }}
          src={props.image}
          alt=""
        />
      </Box>
    </>
  );
};

export default CoursesSlider;
