import { Box, Container, Stack, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import Guides from "../../components/Guides";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TermsBlock from "../../components/TermsBlock";

import HeaderBgrImg from "../../images/bgr-short.svg";

const Policy = () => {
  return (
    <Box
      position="relative"
      maxWidth={pxToRem(1920)}
      m="0 auto"
      p={{ xs: 0, md: pxToRem(30) }}
      sx={{ overflowX: "hidden" }}
    >
      <Box position="relative">
        <Box
          display="flex"
          height={{ xs: "100px", md: pxToRem(130) }}
          borderRadius={{ xs: 0, md: "40px 40px 0 0" }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: `url("${HeaderBgrImg}")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <Header menuVisible hideUserProfile />
          <Guides color="rgba(0,0,0,0.03)" zIndex="0" />
        </Box>

        <Container
          disableGutters
          sx={{
            pt: { xs: "40px", sm: "50px", md: pxToRem(80) },
            pb: { xs: "60px", sm: "70px", md: pxToRem(140) },
          }}
        >
          <Typography
            variant="sectionTitle"
            component="h2"
            mb={{ xs: "30px", md: "100px" }}
          >
            Privacy <span className="highlighted">Policy</span>
          </Typography>

          <Stack gap={{ xs: "30px", md: "100px" }}>
            <TermsBlock title="Introduction">
              <Typography variant="termsTitle" component="p">
                Welcome to RebYakovMoshe.com (the "Site"). We value your privacy
                and are committed to protecting your personal information. This
                Privacy Policy explains how we collect, use, and safeguard your
                data when you use our services.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Information We Collect">
              <Typography variant="termsTitle" component="p">
                1. Personal Information: When you register on our Site, we may
                collect personal information such as your name, email address,
                billing information, and any other information you voluntarily
                provide.
                <br />
                2. Usage Data: We automatically collect information about your
                interactions with the Site, such as your IP address, browser
                type, pages visited, and the time spent on our Site.
              </Typography>
            </TermsBlock>

            <TermsBlock title="How We Use Your Information">
              <Typography variant="termsTitle" component="p">
                We use the information we collect to:
                <br />
                <br />
                1. Provide and maintain our services.
                <br />
                2. Process transactions and send you related information,
                including purchase confirmations and invoices.
                <br />
                3. Respond to your comments, questions, and provide customer
                service.
                <br />
                4. Improve our Site, services, and marketing efforts.
                <br />
                5. Send you updates, promotional materials, and other
                information related to our services, subject to your
                communication preferences.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Sharing Your Information">
              <Typography variant="termsTitle" component="p">
                We do not sell, trade, or otherwise transfer your personal
                information to outside parties except to trusted third parties
                who assist us in operating our Site, conducting our business, or
                servicing you, so long as those parties agree to keep this
                information confidential.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Cookies & Tracking Technologies">
              <Typography variant="termsTitle" component="p">
                Our Site may use cookies and similar tracking technologies to
                enhance your experience.
                <br />
                Cookies are small data files stored on your device that help us
                understand your preferences based on previous or current site
                activity.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Data Security">
              <Typography variant="termsTitle" component="p">
                We implement a variety of security measures to maintain the
                safety of your personal information. However, no method of
                transmission over the internet, or method of electronic storage,
                is 100% secure. Therefore, we cannot guarantee its absolute
                security.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Your Choices">
              <Typography variant="termsTitle" component="p">
                You can choose not to provide personal information, but this may
                limit your ability to use certain features of the Site. You can
                also opt out of receiving promotional communications from us by
                following the unsubscribe instructions provided in those
                communications.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Changes to This Privacy Policy">
              <Typography variant="termsTitle" component="p">
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page. You are advised to review this Privacy Policy
                periodically for any changes.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Contact Us">
              <Typography variant="termsTitle" component="p">
                If you have any questions about this Privacy Policy, please
                contact us at:
                <br />
                <br />
                Email: support@rebyakovmoshe.com
                <br />
                Address: [Your Business Address]
              </Typography>
            </TermsBlock>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default Policy;
