import {
  Box,
  Button,
  Collapse,
  Divider,
  Fade,
  IconButton,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { MediaPlayButton } from "@vidstack/react";

import { ReactComponent as Replay } from "../../images/replay.svg";
import { ReactComponent as NextLecture } from "../../images/play-video.svg";
import { ReactComponent as Check } from "../../images/check.svg";

import { ReactComponent as Close } from "../../images/close.svg";
import { pxToRem } from "px2rem2px";
import { useEffect, useState } from "react";
import { padding, width } from "@mui/system";
import { remindMeAtApi } from "../../api/course";
import Toast from "../Toast";

const EndView = ({
  isPreview,
  content,
  setPlayIcon,
  setPlayTriggered,
  lastLesson,
  isFixed,
  setIsFixed,
  handleNextLectureClick,
  lessons,
  selectedLesson,
  videoEnded,
  setVideoEnded,
  hoursRestriction,
  setBlockAutoLectureId = () => {},
  setRemindMeAt,
}) => {
  const filteredWhatsIncluded = content?.whats_included_preview?.filter(
    (item) => item.trim() !== ""
  );

  // const filteredWhatsIncluded = ["wwww wwwww www","wwww wwwww www","wwww wwwww www", "wwwwwwwwwwwwwwww", "wwwwwwwwwwwwwwww", "wwwwwwwwwwwwwwww"]

  // hours remaining
  const [showNextOptions, setShowNextOptions] = useState(false);
  const [nextLessonTime, setNextLessonTime] = useState(null);
  const [intervalText, setIntervalText] = useState("");
  const [isNextLessonBlocked, setIsNextLessonBlocked] = useState("");
  const [timeLeft, setTimeLeft] = useState("");
  const [contentVisible, setContentVisible] = useState(false);
  const [canWatchNext, setCanWatchNext] = useState(true);

  useEffect(() => {
    if (!lessons || !selectedLesson) return;

    // Find the next lesson
    const currentIndex = lessons.findIndex(
      (lesson) => lesson.id === selectedLesson.id
    );
    const nextLesson = lessons[currentIndex + 1];

    // if (nextLesson && nextLesson?.can_watch_next_lesson) {
    if (nextLesson && nextLesson?.status !== "locked") {
      setCanWatchNext(true);
      setIsNextLessonBlocked(false);
    }

    if (
      nextLesson &&
      nextLesson.hours_remaining &&
      (nextLesson.hours_remaining.hours > 0 ||
        nextLesson.hours_remaining.minutes > 0)
    ) {
      setIsNextLessonBlocked(true);
      const { hours, minutes } = nextLesson.hours_remaining;
      setTimeLeft(
        `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`
      );
      // total seconds
      const totalSeconds = hours * 3600 + minutes * 60;
      setNextLessonTime(totalSeconds);
    }
  }, [lessons, selectedLesson]);

  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  useEffect(() => {
    setShowNextOptions(false);
    if (videoEnded) {
      const delay = 3000; // delay 1sec
      const timer = setTimeout(() => {
        setShowNextOptions(true);
      }, delay);
      return () => clearTimeout(timer);
    }
  }, [videoEnded]);

  // remind me click
  const [disableReminder, setDisableReminder] = useState(false);
  const handleRemindMeClick = async (time) => {
    const now = new Date();

    const futureTime = new Date(now.getTime() + time * 1000);
    console.log("futureTime", futureTime);
    setRemindMeAt(true);
    try {
      const res = await remindMeAtApi(selectedLesson.id, futureTime);
      if (res) {
        console.log(res);
        setDisableReminder(true);
      }
    } catch (error) {
      console.error("Error reminding me:", error);
      if (
        error?.response?.data?.error ===
        "A reminder for this lesson already exists."
      ) {
        setDisableReminder(true);
      }
    }
  };

  return (
    <>
      <Stack
        className="end-overlay"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        width="100%"
        height="100%"
        bgcolor="#026670"
        zIndex={28}
        borderRadius={{ xs: 0, md: 4 }}
        p="15px 40px"
        sx={{
          inset: 0,
          display: "none",
          "& media-play-button": { width: "auto", height: "auto", m: 0 },
        }}
      >
        {isFixed && (
          <IconButton className="pip-close" onClick={() => setIsFixed(false)}>
            <Close color="#fff" width={pxToRem(10)} height={pxToRem(10)} />
          </IconButton>
        )}

        {isPreview ? (
          <>
            <Box overflow="auto">
              <Stack spacing={4} alignItems="center">
                <MediaPlayButton>
                  <Button
                    variant="yellow"
                    startIcon={<Replay />}
                    sx={{
                      fontSize: "16px",
                      height: pxToRem(54),
                      maxWidth: "225px",
                    }}
                    onClick={() => {
                      const overlayElement =
                        document.querySelector(".end-overlay");
                      if (overlayElement) {
                        overlayElement.style.display = "none";
                      }
                      setPlayTriggered(true);
                      if (setPlayIcon) setPlayIcon(true);
                    }}
                  >
                    Replay Preview
                  </Button>
                </MediaPlayButton>
              </Stack>
              {filteredWhatsIncluded?.length > 0 && (
                <>
                  <Divider
                    sx={{
                      backgroundColor: "rgba(255,255,255,0.3)",
                      my: isFixed ? "20px" : "30px",
                      alignSelf: "stretch",
                      display: { xs: "none", ssm: "block" },
                    }}
                  />
                  {!mobile_ssm && (
                    <Box textAlign="center">
                      <Typography
                        component="p"
                        fontSize={isFixed ? pxToRem(16) : pxToRem(18)}
                        fontWeight={600}
                        color="#fff"
                        mb="10px"
                        mt={{ xs: "10px", md: 0 }}
                      >
                        What's included?
                      </Typography>
                      <Box
                        sx={{
                          display:
                            filteredWhatsIncluded.length > 3 ? "grid" : "flex",
                          flexDirection: "column",
                          gridTemplateColumns:
                            filteredWhatsIncluded.length > 3
                              ? "1fr 1fr"
                              : "unset",
                          alignItems: "center",
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                        gap={isFixed ? "5px 20px" : "7px 30px"}
                      >
                        {filteredWhatsIncluded.map((item, index) => (
                          <CourseFeature key={index}>{item}</CourseFeature>
                        ))}
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </>
        ) : (
          <Stack spacing={mobile_ssm?1:4} alignItems="center" overflow="auto">
            {lastLesson && (
              <Typography
                sx={{

                  fontSize: { xs: pxToRem(20), ssm: "26px" },
                  color: "#fce181",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                Congrats! You've Completed This Course!
              </Typography>
            )}
            <MediaPlayButton style={{ width: "100%", padding: 0 }}>
              <Button
                variant="yellow"
                startIcon={<Replay />}
                sx={{
                  fontSize: "16px",
                  height: { xs: "44px", ssm: pxToRem(54) },
                  maxWidth: "225px",
                }}
                onClick={() => {
                  const overlayElement = document.querySelector(".end-overlay");
                  if (overlayElement) {
                    overlayElement.style.display = "none";
                  }
                  setVideoEnded(false);
                  setPlayTriggered(true);
                  setBlockAutoLectureId(true);
                  if (setPlayIcon) setPlayIcon(true);
                }}
              >
                Replay Lecture
              </Button>
            </MediaPlayButton>

            <Collapse
              in={showNextOptions}
              timeout="auto"
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              onEntered={() => setContentVisible(true)}
              onExiting={() => setContentVisible(false)}
            >
              <Box
                sx={{
                  opacity: contentVisible ? 1 : 0,
                  transition: "opacity 0.5s ease-in-out",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!lastLesson && (
                  <>
                    {isNextLessonBlocked ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          marginTop: "20px",
                        }}
                      >
                        {!mobile_ssm && (
                          <Typography
                            sx={{
                              fontSize: pxToRem(22),
                              color: "#fce181",
                              textAlign: "center",
                              fontWeight: 500,
                              padding: "0 50px",
                            }}
                          >
                            {!isFixed && (
                              <>
                                To allow time for material to be fully absorbed,
                                there is a{" "}
                                <Typography
                                  component="span"
                                  sx={{
                                    fontWeight: 700,
                                    fontSize: "20px",
                                  }}
                                >
                                  {hoursRestriction}-hour
                                  {hoursRestriction === 1 ? "" : "s"} interval
                                </Typography>{" "}
                                before the next lecture.
                              </>
                            )}
                          </Typography>
                        )}

                        <Button
                          variant="outlined"
                          sx={{
                            fontSize: "16px",
                            height: { xs: "44px", ssm: pxToRem(54) },
                            maxWidth: "225px",
                            width: "100%",
                            border: "1px solid #FCE181",
                            color: "#FCE181",
                            backgroundColor: "#026670",
                            marginTop: isFixed ? "0px" : "20px",
                            ":hover": {
                              border: "1px solid #FCE181",
                              backgroundColor: "#FCE181",
                              color: "#026670",
                            },
                            "&.Mui-disabled": {
                              border: "1px solid #333333",
                              color: "#333333",
                              backgroundColor: "#edece8",
                            },
                          }}
                          onClick={() => {
                            handleRemindMeClick(nextLessonTime);
                          }}
                          disabled={disableReminder}
                        >
                          Remind Me in {timeLeft}
                        </Button>
                      </Box>
                    ) : canWatchNext ? (
                      <Button
                        variant="yellow"
                        startIcon={<NextLecture width={pxToRem(28)} />}
                        sx={{
                          fontSize: "16px",
                          height: { xs: "44px", ssm: pxToRem(54) },
                          maxWidth: "225px",
                          width: "100%",
                        }}
                        onClick={handleNextLectureClick}
                      >
                        Next Lecture
                      </Button>
                    ) : null}
                  </>
                )}
              </Box>
            </Collapse>
          </Stack>
        )}
      </Stack>
    </>
  );
};

const CourseFeature = (props) => {
  return (
    <Stack flexDirection="row" alignItems="baseline" gap="10px">
      <Check color="#FCE181" style={{ flexShrink: 0 }} />
      <Typography fontSize="14px" fontWeight={500} color="#F7F6F2">
        {props.children}
      </Typography>
    </Stack>
  );
};

export default EndView;
