import { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Box, Container, Grid, Typography } from "@mui/material";
import Guides from "../../components/Guides";
import Header from "../../components/Header";
import HeaderBgrImg from "../../images/bgr-short.svg";
import BlockBordered from "../../components/BlockBordered";
import { pxToRem } from "px2rem2px";
import ContactCard from "../../components/ContactCard";
import Philosophy from "../../images/philosophy-img.png";
import CustomerService from "../../images/customer-service.svg";
import { ReactComponent as Chevron } from "../../images/chevron.svg";
import { Stack } from "@mui/system";

const contactHelpAndSupportCardsDetails = [
  {icon: Philosophy, title: "Need 1 on 1 help?", description: "Talk to <span style='font-weight: 700;'> Reb Yakov Moshe!</span> Schedule an appointment now to get personalized guidance and support. He’s here to answer your questions and provide the help you need. <span style='font-weight: 700;'>Book your session today!</span>", btnName: "Schedule Appointment", rounded: true },
  {icon: CustomerService, title: "Technical Support", description: "For <span style='font-weight: 700;'> website or account support,</span> contact our technical support team for quick and efficient assistance with any issues you encounter.", btnName: "Contact Support" }
]

const categoryNum1 = [
  {question: "How do I navigate through out the website?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in <span style='color: #026670; font-weight: 600'>voluptate velit esse cillum</span> dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in <span style='font-weight: 600'>culpa qui officia deserunt</span> mollit anim id est laborum."},
  {question: "How do I navigate through out the website?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in <span style='color: #026670; font-weight: 600'>voluptate velit esse cillum</span> dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in <span style='font-weight: 600'>culpa qui officia deserunt</span> mollit anim id est laborum."},
  {question: "How do I navigate through out the website?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in <span style='color: #026670; font-weight: 600'>voluptate velit esse cillum</span> dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in <span style='font-weight: 600'>culpa qui officia deserunt</span> mollit anim id est laborum."},
  {question: "How do I navigate through out the website?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in <span style='color: #026670; font-weight: 600'>voluptate velit esse cillum</span> dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in <span style='font-weight: 600'>culpa qui officia deserunt</span> mollit anim id est laborum."},
]
const categoryNum2 = [
  {question: "How do I navigate through out the website?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in <span style='color: #026670; font-weight: 600'>voluptate velit esse cillum</span> dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in <span style='font-weight: 600'>culpa qui officia deserunt</span> mollit anim id est laborum."},
  {question: "How do I navigate through out the website?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in <span style='color: #026670; font-weight: 600'>voluptate velit esse cillum</span> dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in <span style='font-weight: 600'>culpa qui officia deserunt</span> mollit anim id est laborum."},
  {question: "How do I navigate through out the website?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in <span style='color: #026670; font-weight: 600'>voluptate velit esse cillum</span> dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in <span style='font-weight: 600'>culpa qui officia deserunt</span> mollit anim id est laborum."},
]
const categoryNum3 = [
  {question: "How do I navigate through out the website?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in <span style='color: #026670; font-weight: 600'>voluptate velit esse cillum</span> dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in <span style='font-weight: 600'>culpa qui officia deserunt</span> mollit anim id est laborum."},
  {question: "How do I navigate through out the website?", answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in <span style='color: #026670; font-weight: 600'>voluptate velit esse cillum</span> dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in <span style='font-weight: 600'>culpa qui officia deserunt</span> mollit anim id est laborum."},
]

const QaAccordion = ({question, answer, expanded, handleChange, panel}) => {
  return (
    <Accordion 
      expanded={expanded === panel}
      onChange={handleChange(panel)}
      sx={{borderRadius: {xs: `${pxToRem(10)} !important`, ssm: `${pxToRem(30)} !important`, sm: `${pxToRem(40)} !important`}, margin: "0", backgroundColor: "#EDECE8", "&.Mui-expanded": {"&::after": { content: 'none' }, boxShadow: "none", margin: 0}, "& .MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded": {padding: {xs: `0 ${pxToRem(30)} 0 ${pxToRem(20)} !important`, ssm: `0 ${pxToRem(50)} !important`}}}}>
      <AccordionSummary
        expandIcon={<Chevron color="#026670" />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{minHeight: {xs: `${pxToRem(90)} !important`, ssm: `${pxToRem(100)} !important`}, gap: `${pxToRem(20)} !important`, "&.Mui-expanded .MuiTypography-root": {color: "#026670 !important"}}}
      >
        <Typography fontSize={{xs: pxToRem(20), ssm: pxToRem(22)}} fontWeight={700} color="secondary">
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{fontWeight: 500, fontSize: pxToRem(18), lineHeight: pxToRem(30), padding: {xs: `0 ${pxToRem(20)} ${pxToRem(20)} ${pxToRem(20)} !important`, ssm: `0 ${pxToRem(50)} ${pxToRem(35)} ${pxToRem(50)} !important`}}} dangerouslySetInnerHTML={{ __html: answer }} />
    </Accordion>
  )
}

const Faq = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box position="relative">
      <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
      <Box
        display="flex"
        height={{ xs: "100px", md: pxToRem(130) }}
        borderRadius={{ xs: 0, md: "40px 40px 0 0" }}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url("${HeaderBgrImg}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <Header menuVisible hideUserProfile />
        <Guides color="rgba(0,0,0,0.03)" zIndex="0" />
      </Box>
      <Container sx={{ px: pxToRem(30)}} disableGutters>
        <Box sx={{padding: {xs: `${pxToRem(55)} 0 ${pxToRem(70)} 0`, ssm: `${pxToRem(60)} 0 ${pxToRem(120)} 0`, sm: `${pxToRem(80)} 0 ${pxToRem(120)} 0`}}}>
          <Grid container rowSpacing={{xs: pxToRem(60), ssm: pxToRem(80), lg: 0}}>
            <Grid item lg={8}>
              <Box sx={{paddingRight: {lg: pxToRem(30)}}}>
                <Box maxWidth={{md: pxToRem(552)}}>
                  <Typography
                    variant="sectionTitle"
                    component="h2"
                    mb={{ xs: "20px", ssm: pxToRem(23) }}
                    mt={{ ssm: pxToRem(8) }}
                    fontSize={{xs: "30px", md: pxToRem(45)}}
                  >
                    Have 
                    <span className="highlighted"> Questions?</span>
                  </Typography>
                  <BlockBordered mb={{ xs: "40px", sm: pxToRem(60) }}>
                    <Typography maxWidth={pxToRem(522)} variant="large">
                      Find answers to common questions in our FAQ section. Explore topics on account management, website navigation, technical troubleshooting, and more.
                    </Typography>
                  </BlockBordered>
                </Box>
                <Typography variant="blockTitle" component="h3" fontSize={{xs: pxToRem(22), md: pxToRem(25)}} mb={pxToRem(33)} mt={pxToRem(60)}>Category Number #1</Typography>
                <Stack rowGap={pxToRem(10)}>
                  {categoryNum1.map((item, index)=>(<QaAccordion key={index} panel={`panel${index}_cat1`} question={item.question} answer={item.answer} expanded={expanded} handleChange={handleChange}/>))}
                </Stack>
                <Typography variant="blockTitle" component="h3" fontSize={{xs: pxToRem(22), md: pxToRem(25)}} mb={pxToRem(33)} mt={pxToRem(60)}>Category Number #2</Typography>
                <Stack rowGap={pxToRem(10)}>
                  {categoryNum2.map((item, index)=>(<QaAccordion key={index} panel={`panel${index}_cat2`} question={item.question} answer={item.answer} expanded={expanded} handleChange={handleChange}/>))}
                </Stack>
                <Typography variant="blockTitle" component="h3" fontSize={{xs: pxToRem(22), md: pxToRem(25)}} mb={pxToRem(33)} mt={pxToRem(60)}>Category Number #3</Typography>
                <Stack rowGap={pxToRem(10)}>
                  {categoryNum3.map((item, index)=>(<QaAccordion key={index} panel={`panel${index}_cat3`} question={item.question} answer={item.answer} expanded={expanded} handleChange={handleChange}/>))}
                </Stack>
              </Box>
            </Grid>
            <Grid item lg={4}>
              <Grid container rowSpacing={pxToRem(20)} columnSpacing={{ssm: pxToRem(20), lg: 0}} sx={{paddingLeft: {lg: pxToRem(15)}}}>
                <ContactCard cardsDetails={contactHelpAndSupportCardsDetails} gridSm={6} gridLg={12} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default Faq;
