import React from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import Guides from "../../components/Guides";

import { ReactComponent as Logo } from "../../images/RYM-Logo.svg";
import BgImg from "../../images/404-bg.png";
import TreeImg from "../../images/white-tree.svg";
import Man from "../../images/man-404.png";

const Text404 = styled(Typography)({
  position: "absolute",
  fontSize: "clamp(7.625rem, 16.356vw + 4.354rem, 19.688rem)",
  fontWeight: 900,
  top: "50%",
  left: "50%",
  writingMode: "vertical-rl",
  textOrientation: "mixed",
  transform: "translate(-120%,-50%) rotate(180deg)",
  "@media (max-width: 1199px)": {
    left: 0,
    transform: "translateY(-50%) rotate(180deg)",
  },
  "@media (max-width: 767px)": {
    position: "static",
    transform: "none",
    writingMode: "initial",
  },
});

const NotFoundPage = () => {
  return (
    <>
      <Guides color="rgba(0,0,0,0.03)" zIndex={1} />
      <Box
        position="relative"
        display="flex"
        alignItems={{ xs: "flex-start", sm: "center" }}
        m={{ xs: 0, sm: "30px" }}
        minHeight={{ xs: "100vh", sm: "calc(100vh - 60px)" }}
        overflow={{ xs: "hidden auto", sm: "hidden" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: { xs: 24, sm: 47 },
            right: { xs: 27, sm: 25, md: 80 },
            zIndex: 1,
          }}
        >
          <Link to="/" style={{ display: "flex" }}>
            <Logo style={{ width: "100px", height: "100%" }} />
          </Link>
        </Box>
        <img src={BgImg} className="bg404" alt="" />
        <img src={TreeImg} className="tree404" alt="" />
        <Stack
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent={{ xs: "flex-start", sm: "space-between" }}
          gap={{ xs: "24px", sm: 0 }}
          flex={1}
          zIndex={1}
          paddingBottom={{ xs: "47px", sm: 0 }}
        >
          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "center", sm: "flex-start" }}
            gap={{ xs: "12px", sm: 0 }}
            mt={{ xs: "80px", sm: 0 }}

          >
            <Text404 variant="h1">404</Text404>
            <Box  
            sx={{
              marginRight: '40px', 
              '@media (min-width:444px)': {
                marginRight: '55px', 
              },
            }}
            >
              <img src={Man} className="man404-img" alt="" />
            </Box>
          </Stack>
          <Box
            sx={{
              flex: 1,
              textAlign: { xs: "center", sm: "right" },
              maxWidth: "360px",
              marginLeft: "auto",
              marginRight: { xs: "auto", sm: "25px", md: "80px" },
              padding: { xs: "0 25px", sm: 0 },
            }}
          >
           
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "22px", md: "25px" },
                fontWeight: "bold",
                mb: { xs: "40px", sm: "23px" },
                color: "#5F5F5F",
                // backgroundColor: "pink",
              }}
            >
              Page Not Found
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "22px", md: "25px" },
                fontWeight: "bold",
                mb: { xs: "40px", sm: "23px" },
              }}
            >
              Looks Like You're a Bit Lost
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: "40px",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              Reb Yakov Moshe would say, "Every mistake is an opportunity to
              learn!" But this page might not teach you anything.
            </Typography>
            <Box
              sx={{
                display: { xs: "flex", sm: "inline-flex" },
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                gap: "10px",
              }}
            >
              <Button
                to="/courses"
                variant="green"
                component={Link}
                sx={{ width: { xs: "100%", sm: "auto" } }}
              >
                Explore Courses
              </Button>
              <Button
                to="/"
                variant="outlined"
                component={Link}
                sx={{ width: { xs: "100%", sm: "max-content" } }}
              >
                Home
              </Button>
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default NotFoundPage;
