import { useState } from "react";
import { Box, Collapse } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { Link } from "react-router-dom";
import LinkBtn from "./LinkBtn";

const NestedMenu = (props) => {
  let currentlyHovering = false;
  const [open, setOpen] = useState(false);

  const handleHover = () => {
    currentlyHovering = true;
    setOpen(true);
  };

  const handleCloseHover = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        setOpen(false);
      }
    }, 50);
  };

  return (
    <Box
      onMouseEnter={handleHover}
      onMouseLeave={handleCloseHover}
      sx={{
        position: "relative",
        "&:hover .MuiLink-root span::after": {
          width: "100%",
        },
      }}
      width="100%"
    >
      <LinkBtn
        title={props.title}
        component={Link}
        onClick={(e) => e.preventDefault()} 
        useFirstStyle={true}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pl: pxToRem(20),
            mt: pxToRem(15),
            a: {
              fontSize: pxToRem(15),
              fontWeight: 600,
              marginBottom: pxToRem(10),
            },
            paddingBottom: pxToRem(10),
          }}
        >
          {props.children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default NestedMenu;
