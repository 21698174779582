// import React, { useEffect, useRef, useState } from "react";
// import "react-quill/dist/quill.snow.css";
// import PropTypes from "prop-types";

// const RichTextDisplay = ({
//   str,
//   showFull = false,
//   onContentExceedsMaxHeight = () => {},
// }) => {
//   const contentRef = useRef(null);
//   const [contentExceedsMaxHeight, setContentExceedsMaxHeight] = useState(false);

//   useEffect(() => {
//     if (contentRef.current) {
//       const exceedsMaxHeight = contentRef.current.scrollHeight > 400;
//       setContentExceedsMaxHeight(exceedsMaxHeight);
//       onContentExceedsMaxHeight(exceedsMaxHeight);
//     }
//   }, [str, onContentExceedsMaxHeight]);

//   if (!str) {
//     return null;
//   }
//   const content = str;

//   return (
//     <div
//       ref={contentRef}
//       className={`rich-text-display ${showFull ? "expanded" : ""}`}
//       dangerouslySetInnerHTML={{ __html: content }}
//       style={{
//         fontWeight: 500,
//         maxHeight: showFull ? "none" : "400px",
//         overflow: "hidden",
//       }} // Adjust maxHeight
//     />
//   );
// };

// RichTextDisplay.propTypes = {
//   str: PropTypes.string.isRequired,
//   showFull: PropTypes.bool,
//   onContentExceedsMaxHeight: PropTypes.func,
// };

// export default RichTextDisplay;
import React, { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";

const RichTextDisplay = ({ str, showFull = false, onContentExceedsMaxHeight = () => {} }) => {
  const contentRef = useRef(null);
  const [contentExceedsMaxHeight, setContentExceedsMaxHeight] = useState(false);

  useEffect(() => {
    if (contentRef.current) {
      const exceedsMaxHeight = contentRef.current.scrollHeight > 400; // Adjust height here
      setContentExceedsMaxHeight(exceedsMaxHeight);
      onContentExceedsMaxHeight(exceedsMaxHeight);
    }
  }, [str, onContentExceedsMaxHeight]);

  if (!str) {
    return null;
  }

  const content = str;

  return (
    <div style={{ position: "relative", maxHeight: showFull ? "none" : "400px", overflow: "hidden" }}>
      {/* Content with dynamic height */}
      <div
        ref={contentRef}
        className={`rich-text-display ${showFull ? "expanded" : ""}`}
        dangerouslySetInnerHTML={{ __html: content }}
        style={{
          fontWeight: 500,
          maxHeight: showFull ? "none" : "400px", // Control height here
          overflow: "hidden",
          // backgroundColor: "#F7F6F2", // Set background to match the gradient
        }}
      />
      {/* Blurring effect applied conditionally */}
      {!showFull && contentExceedsMaxHeight && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "50px", // Adjust the height of the blur effect here
            background: "linear-gradient(to bottom, rgba(247, 246, 242, 0) 0%, rgba(247, 246, 242, 1) 100%)",
            pointerEvents: "none", // Ensures the gradient is not interactive
          }}
        />
      )}
    </div>
  );
};

RichTextDisplay.propTypes = {
  str: PropTypes.string.isRequired,
  showFull: PropTypes.bool,
  onContentExceedsMaxHeight: PropTypes.func,
};

export default RichTextDisplay;
