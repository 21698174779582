import { Box, Container, Stack, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import Guides from "../../components/Guides";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TermsBlock from "../../components/TermsBlock";

import HeaderBgrImg from "../../images/bgr-short.svg";

const Terms = () => {
  return (
    <Box
      position="relative"
      maxWidth={pxToRem(1920)}
      m="0 auto"
      p={{ xs: 0, md: pxToRem(30) }}
      sx={{ overflowX: "hidden" }}
    >
      <Box position="relative">
        <Box
          display="flex"
          height={{ xs: "100px", md: pxToRem(130) }}
          borderRadius={{ xs: 0, md: "40px 40px 0 0" }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: `url("${HeaderBgrImg}")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <Header menuVisible hideUserProfile />
          <Guides color="rgba(0,0,0,0.03)" zIndex="0" />
        </Box>

        <Container
          disableGutters
          sx={{
            pt: { xs: "40px", sm: "50px", md: pxToRem(80) },
            pb: { xs: "60px", sm: "70px", md: pxToRem(140) },
          }}
        >
          <Typography
            variant="sectionTitle"
            component="h2"
            mb={{ xs: "30px", md: "100px" }}
          >
            Terms & <span className="highlighted">Conditions</span>
          </Typography>

          <Typography
            variant="termsTitle"
            component="p"
            mb={{ xs: "40px", md: "60px" }}
          >
            Last updated: 7/23/2024
            <br />
            <br />
            Welcome to RebYakovMoshe.com. Please read these Terms & Conditions
            carefully before using our website. By accessing or using our site,
            you agree to be bound by these terms. If you do not agree with any
            part of these terms, you may not access the website.
          </Typography>

          <Stack gap={{ xs: "30px", md: "100px" }}>
            <TermsBlock title="General">
              <Typography variant="termsTitle" component="p">
                RebYakovMoshe.com provides online courses for purchase and free
                viewing, delivered by Reb Yakov Moshe. These terms apply to all
                users of the site.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Account Registration">
              <Typography variant="termsTitle" component="p">
                - You must register for an account to access certain features.
                <br />
                - You are responsible for maintaining the confidentiality of
                your account and password.
                <br />- You agree to accept responsibility for all activities
                that occur under your account.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Course Purchases and Access">
              <Typography variant="termsTitle" component="p">
                - Course purchases grant you a non-exclusive, non-transferable
                license to access the content.
                <br />
                - Access to purchased courses is typically provided
                indefinitely, but RebYakovMoshe.com reserves the right to change
                or discontinue any course at any time.
                <br />- You are prohibited from downloading any course content
                unless an explicit download option is provided.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Refund Policy">
              <Typography variant="termsTitle" component="p">
                - Refunds for purchased courses may be issued within 7 days of
                purchase if you are not satisfied with the content.
                <br />- To request a refund, please contact our support team
                with your purchase details.
              </Typography>
            </TermsBlock>

            <TermsBlock title="User Conduct">
              <Typography variant="termsTitle" component="p">
                - You agree not to use the site for any unlawful purpose.
                <br />
                - You are prohibited from uploading or sharing any content that
                is offensive, defamatory, or infringes on intellectual property
                rights.
                <br />- You are prohibited from downloading any course content
                that does not have a download option.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Intellectual Property">
              <Typography variant="termsTitle" component="p">
                - All content on RebYakovMoshe.com, including but not limited to
                text, graphics, and videos, is the property of RebYakovMoshe.com
                or its licensors and is protected by copyright and other laws.
                <br />- You may not reproduce, distribute, or create derivative
                works from any content on the site without explicit permission.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Privacy">
              <Typography variant="termsTitle" component="p">
                - Your privacy is important to us. Please review our Privacy
                Policy for information on how we collect, use, and protect your
                personal information.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Limitation of Liability">
              <Typography variant="termsTitle" component="p">
                - RebYakovMoshe.com is not liable for any damages arising from
                your use of the site or any content available on it.
                <br />- The site and content are provided "as is" without any
                warranties of any kind.
              </Typography>
            </TermsBlock>

            <TermsBlock title="User Suspension and Termination">
              <Typography variant="termsTitle" component="p">
                - RebYakovMoshe.com reserves the right to suspend or terminate
                your account at any time, without notice and for any reason.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Changes to Terms">
              <Typography variant="termsTitle" component="p">
                - We may update these Terms & Conditions from time to time. Any
                changes will be posted on this page.
                <br />- Your continued use of the site after any changes
                signifies your acceptance of the new terms.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Governing Law and Jurisdiction">
              <Typography variant="termsTitle" component="p">
                - These Terms & Conditions are governed by the laws of the State
                of New York, without regard to its conflict of law principles.
                <br />- You agree that any legal action or proceeding arising
                out of or relating to these Terms & Conditions or your use of
                the website shall be brought exclusively in the state or federal
                courts located in New York, New York, and you consent to the
                personal jurisdiction of these courts.
              </Typography>
            </TermsBlock>

            <TermsBlock title="Contact Us">
              <Typography variant="termsTitle" component="p">
                If you have any questions about these Terms & Conditions, please
                contact us at support@rebyakovmoshe.com.
                <br />
                <br />
                By using RebYakovMoshe.com, you acknowledge that you have read,
                understood, and agree to be bound by these Terms & Conditions.
              </Typography>
            </TermsBlock>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default Terms;
